import { PageHeader } from 'antd';
import { ContentPage } from 'components/content/content';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root/root.context';
import { useLocation, useParams } from 'react-router-dom';

import { GameSettingsForm } from './game-settings-form';

export const GameEdit = observer(function GameEdit() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { games } = useStores();

  games.show(id);

  return (
    <ContentPage
      key={location.key}
      style={{ marginRight: 300 }}
      header={
        <PageHeader
          onBack={() => window.history.back()}
          title="Games"
          subTitle="Settings"
        />
      }>
      <GameSettingsForm item={games.selected} />
    </ContentPage>
  );
});
