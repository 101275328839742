import { Page404 } from 'components/error-page';
import Games from 'pages/games';
import Users from 'pages/users';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

export const AppRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}users`}>
        <Users />
      </Route>
      <Route path={`${path}games`}>
        <Games />
      </Route>
      <Route exact path="/">
        <Redirect to={`${path}games`} />
      </Route>
      <Route component={() => <Page404 />} />
    </Switch>
  );
};
