import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { UserAdd } from './add-user.page';
import { UsersListPage } from './users-list.page';

export const UsersRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/add`}>
        <UserAdd />
      </Route>
      <Route exact path={`${path}`}>
        <UsersListPage />
      </Route>
    </Switch>
  );
};
