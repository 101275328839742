import './style.css';

import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';

import { Logo } from '../logo/logo';
import { UserNavBar } from './user-navbar';

const { Header: AntHeader } = Layout;

export function Header() {
  return (
    <AntHeader className="page-header">
      <Row gutter={0} justify="space-between">
        <Col className="gutter-row" span={6}>
          <Link to="/">
            <Logo />
          </Link>
        </Col>
        <Col className="gutter-row" span={18}>
          <UserNavBar />
        </Col>
      </Row>
    </AntHeader>
  );
}
