import { flow, getEnv, getParent, Instance, types } from 'mobx-state-tree';

const User = types
  .model('User', {
    id: types.identifierNumber,
    email: types.string,
    firstName: types.string,
    lastName: types.string,
    isActive: types.boolean,
  })
  .views((self) => ({
    get key() {
      return `${self.id}`;
    },
    get name() {
      return `${self.firstName} ${self.lastName}`;
    },
  }))
  .actions((store) => ({
    remove() {
      const parent = getParent<typeof UserListModel>(store, 2);
      parent.remove(store as TUserModel);
    },
  }));

export const UserListModel = types
  .model('UserListModel', {
    list: types.array(User),
    page: types.optional(types.number, 1),
  })
  .actions((store) => {
    const { api } = getEnv(store);

    return {
      remove(item: TUserModel) {
        store.list.splice(store.list.indexOf(item), 1);
      },
      getList: flow(function* getList(page) {
        const { data } = yield api.get(`/user/list?limit=10&page=${page}`);
        store.list = data.items;
        return data;
      }),
      add: flow(function* addUser(formData) {
        const data = {
          ...formData,
          isActive: true,
        };
        return yield api.post('/user', data);
      }),
    };
  });

type TUserModel = Instance<typeof User>;
