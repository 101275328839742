import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Upload,
} from 'antd';
import { PreviewActionType, usePreview } from 'contexts/Preview.context';
import { TGameModel } from 'models/games/games.model';
import { useState } from 'react';

const { Meta } = Card;

const itemForm = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

export interface IStyleItemsProps {
  prefix: string;
  name: string;
  children?: React.ReactNode;
}

export function UploadFile(
  props: IStyleItemsProps & {
    value?: TGameModel;
    example?: string;
    form: FormInstance;
  },
) {
  const { prefix = '', name = '', value, form, example, children } = props;
  const file = value?.settings?.[prefix]?.file
    ? `/upload/${value?.settings?.[prefix]?.file}`
    : null;
  const { dispatch } = usePreview();
  const [tempFile, setTempFile] = useState(file);

  const handleDeleteImage = () => {
    form.setFieldsValue({
      [prefix]: { file: null },
    });
    setTempFile(null);

    dispatch({
      type: PreviewActionType.SET_PREVIEW_VALUE_TO_SEND,
      payload: form.getFieldsValue(),
    });
  };

  return (
    <>
      <Divider orientation="left" {...props}>
        {name}
      </Divider>
      <Form.Item hidden name={[prefix, 'file']} {...itemForm}>
        <Input />
      </Form.Item>
      <Row justify="space-between">
        <Col span={6}>
          <Upload
            listType="picture-card"
            multiple={false}
            action={`${process.env.REACT_APP_API}/game-settings/upload`}
            onChange={(info) => {
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                form.setFieldsValue({
                  [prefix]: { file: info.file.response.filename },
                });
                setTempFile(info.file.response.filename);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }

              dispatch({
                type: PreviewActionType.SET_PREVIEW_VALUE_TO_SEND,
                payload: form.getFieldsValue(),
              });
            }}>
            <div>
              <PlusOutlined />
            </div>
          </Upload>
        </Col>
        <Col span={14}>
          <Card
            style={{ width: 150 }}
            bordered={false}
            size="small"
            cover={
              <img
                alt=""
                src={file}
                style={!tempFile ? { filter: 'grayscale(1)' } : null}
              />
            }>
            <Meta title="Size" description={children} />
            {!!example && (
              <Button
                style={{ padding: 0 }}
                type="link"
                href={example}
                download={name}>
                Download example
              </Button>
            )}
            {tempFile && (
              <Button type="primary" danger onClick={handleDeleteImage}>
                Delete
              </Button>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
