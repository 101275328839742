import { types } from 'mobx-state-tree';

export const UserModel = types
  .model('UserModel', {
    id: types.identifierNumber,
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    email: types.string,
  })
  .views((self) => ({
    get name() {
      return `${self.firstName} ${self.lastName}`;
    },
  }));
