import Preview from 'components/Preview/Preview';
import { PreviewProvider } from 'contexts/Preview.context';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { GameAdd } from './add-game.page';
import { GameEdit } from './edit-game.page';
import { GamesListPage } from './games-list.page';

export const GamesRoute = () => {
  const { path } = useRouteMatch();

  return (
    <PreviewProvider>
      <Switch>
        <Route exact path={`${path}/add`}>
          <GameAdd />
          <Preview />
        </Route>
        <Route exact path={`${path}/edit/:id`}>
          <GameEdit />
          <Preview />
        </Route>
        <Route exact path={`${path}`}>
          <GamesListPage />
        </Route>
      </Switch>
    </PreviewProvider>
  );
};
