import { UserAddOutlined } from '@ant-design/icons';
import { Button, Form, Input, PageHeader } from 'antd';
import { ContentPage } from 'components/content/content';
import { useStores } from 'models/root/root.context';
import { useMutation } from 'react-query';
import AsyncFormValidationError from 'services/errors/FormValidationsError';
import { L } from 'services/logger';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: 'Please input your ${label}',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

function UserForm() {
  const [form] = Form.useForm();
  const { users } = useStores();
  const mutation = useMutation((data) => users.add(data));

  const onFinish = async (values: any) => {
    try {
      await mutation.mutateAsync(values);
    } catch (e) {
      form.setFields(AsyncFormValidationError(form.getFieldsValue(), e));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    L.error('Failed:', errorInfo);
  };

  return (
    <Form
      {...layout}
      autoComplete="off"
      form={form}
      name="user"
      validateMessages={validateMessages}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Form.Item
        label="First name"
        name="firstName"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Last name" name="lastName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[{ required: true, type: 'email' }]}>
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}>
        <Input.Password autoComplete="off" />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" icon={<UserAddOutlined />}>
          Add
        </Button>
      </Form.Item>
    </Form>
  );
}

export function UserAdd() {
  return (
    <ContentPage
      header={
        <PageHeader
          onBack={() => window.history.back()}
          title="Users"
          subTitle="Add"
        />
      }>
      <UserForm />
    </ContentPage>
  );
}
