import { Avatar, Button, Space } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

type TProps = {
  title: string;
  color: string;
  icon: React.ReactNode;
  location: string;
};

export function MenuItem(props: TProps) {
  const { title, color, icon, location } = props;
  const match = useRouteMatch(location);

  const style = match
    ? {
        backgroundColor: color,
        color: 'white',
        textAlign: 'left' as 'left',
        borderRadius: '12px',
      }
    : {
        textAlign: 'left' as 'left',
      };

  return (
    <Link to={location}>
      <Button block size="large" type="text" onClick={() => null} style={style}>
        <Space>
          <Avatar
            shape="square"
            icon={icon}
            size="small"
            style={{ backgroundColor: color }}
          />
          {title}
        </Space>
      </Button>
    </Link>
  );
}
