/* eslint-disable react/destructuring-assignment */
import { UserAddOutlined } from '@ant-design/icons';
import { Button, PageHeader, Space, Table, Tag } from 'antd';
import { ContentPage } from 'components/content';
import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root/root.context';
import { useQuery } from 'react-query';
import { Link, useRouteMatch } from 'react-router-dom';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => <a href={`users/edit/${record.id}`}>{text}</a>,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (status) =>
      status ? <Tag color="green-inverse">active</Tag> : <Tag>disabled</Tag>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <a href={`users/edit/${record.id}`}>Edit</a>
        <Button type="link" onClick={record.remove}>
          Delete
        </Button>
      </Space>
    ),
  },
];

const UsersList = observer(function UsersList() {
  const { users } = useStores();
  const query = useQuery(['/user/list', users.page], () =>
    users.getList(users.page),
  );

  if (query.isLoading) {
    return <div>trwa ladowanie...</div>;
  }

  return <Table columns={columns} dataSource={values(users.list)} />;
});

export function UsersListPage() {
  const { path } = useRouteMatch();

  return (
    <ContentPage
      header={
        <PageHeader
          title="Users"
          extra={[
            <Link to={`${path}/add`} key="add">
              <Button size="large" type="link" icon={<UserAddOutlined />}>
                Add
              </Button>
            </Link>,
          ]}
        />
      }>
      <UsersList />
    </ContentPage>
  );
}
