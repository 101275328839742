import axios from 'axios';
import { rootStore } from 'models/root/root.model';

import ApiException from '../errors/ApiExceptions';

const API = axios.create({
  baseURL: process.env.REACT_APP_API,
});

API.interceptors.response.use(
  function responseInterceptor(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function rejectInterceptor(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      rootStore.auth.logout();
      return Promise.reject(error);
    }
    throw new ApiException(error);
  },
);

export default API;
