import { flow, getEnv, getParent, Instance, types } from 'mobx-state-tree';
import { queryClient } from 'services/api/query-client';

const Game = types
  .model('Game', {
    id: types.identifierNumber,
    gameId: types.number,
    name: types.string,
    settings: types.frozen({}),
  })
  .actions((store) => {
    const { api } = getEnv(store);

    const saveApiMethod = flow(function* save(data) {
      const response = yield api.patch(`/game-settings/${store.id}`, data);

      return response.data;
    });

    return {
      save: flow(function* save(formData) {
        const { name, gameId, ...settings } = formData;
        const response = yield saveApiMethod({
          gameId: Number(gameId),
          name,
          settings,
        });
        return response;
      }),
      remove: flow(function* remove() {
        const parent = getParent<typeof GamesListModel>(store, 2);
        parent.remove(store as TGameModel);
        const response = yield api.delete(`/game-settings/${store.id}`);
        queryClient.invalidateQueries('/games/list');
        return response.data;
      }),
    };
  });

export const GamesListModel = types
  .model('GamesListModel', {
    list: types.array(Game),
    page: types.optional(types.number, 1),
    selected: types.safeReference(Game),
  })
  .actions((store) => {
    const { api } = getEnv(store);

    return {
      show(id) {
        store.selected = id;
      },
      remove(item: TGameModel) {
        store.list.splice(store.list.indexOf(item), 1);
      },
      getList: flow(function* getList(page) {
        const { data } = yield api.get(`/game-settings?limit=10&page=${page}`);
        store.list = data;
        return data;
      }),
      add: flow(function* addGameSettings(formData) {
        const { name, gameId, ...settings } = formData;
        return yield api.post('/game-settings', {
          gameId: Number(gameId),
          name,
          settings,
        });
      }),
    };
  });

export type TGameModel = Instance<typeof Game>;
