import './Preview.css';

import { Layout } from 'antd';
import React from 'react';

import PreviewIframe from './PreviewIframe';

const { Sider } = Layout;

type PreviewProps = {
  iframeUrls?: string[];
};

// TODO: configure .env
const PREVIEW_URL =
  process.env.REACT_APP_PREVIEW_URL ||
  'https://minigames.bk.netizens.pl/game/preview';

const Preview: React.FunctionComponent<PreviewProps> = ({
  iframeUrls = [PREVIEW_URL, `${PREVIEW_URL}?modal=true`],
}) => {
  if (!iframeUrls?.length) {
    return null;
  }

  return (
    <Sider
      width={300}
      style={{
        backgroundColor: 'white',
        padding: '88px 24px 24px 24px',
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        right: 0,
        top: 0,
      }}>
      <div className="preview__container">
        {iframeUrls.map((iframeUrl) => {
          return <PreviewIframe key={iframeUrl} gameUrl={iframeUrl} />;
        })}
      </div>
    </Sider>
  );
};

export default Preview;
