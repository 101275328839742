import { castToSnapshot, Instance, onSnapshot, types } from 'mobx-state-tree';
import { AuthModel } from 'models/auth/auth.model';
import { UserListModel } from 'models/users/users.model';
import API from 'services/api';

import { GamesListModel } from '../games/games.model';

const environment = { api: API };

const RootModel = types.model({
  auth: AuthModel,
  users: UserListModel,
  games: GamesListModel,
});

let initialState = RootModel.create(
  {
    auth: {},
    users: {},
    games: {},
  },
  environment,
);

const data = localStorage.getItem('rootState');

if (data) {
  const json = JSON.parse(data);
  if (RootModel.is(json)) {
    initialState = RootModel.create(castToSnapshot(json), environment);
  }
}

export const rootStore = initialState;

onSnapshot(rootStore, (snapshot) => {
  localStorage.setItem('rootState', JSON.stringify(snapshot));
});

export type RootInstance = Instance<typeof RootModel>;
