import { rootStore } from 'models/root/root.model';
import Logger from 'services/logger';

export default class ApiException extends Error {
  name = 'ApiException';

  msg: string = '';

  errors: unknown;

  constructor(data, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiException);
    }

    if (data?.response?.status === 401) {
      rootStore.auth.logout();
    }

    const responseMessage = data?.response
      ? `(${data.response.status}) ${data.response.data.error}`
      : '';

    const message = `${responseMessage} | ${data?.message ?? 'Error on API'}`;

    this.msg = message;
    this.errors = data.response.data.message || [];
    // this.status = data.status;

    // Logger.error(this.msg, Object.values(this.err).join('\n'));
    Logger.error(this.msg, data);
  }

  toJSON() {
    return {
      error: {
        name: this.name,
        message: this.msg,
        stacktrace: this.stack,
      },
    };
  }
}
