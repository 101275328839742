import AsyncPage from 'components/async-page/async-component';
import ErrorBoundary from 'components/error-boundary';
import PrivateRoute from 'components/private-route';
import { RootStoreProvider } from 'models/root/root.context';
import { rootStore } from 'models/root/root.model';
import App from 'pages/app';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { queryClient } from 'services/api/query-client';

export default function Index() {
  return (
    <RootStoreProvider value={rootStore}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <BrowserRouter>
            <Switch>
              <Route
                path="/login"
                exact
                component={(props) =>
                  AsyncPage({
                    ...props,
                    page: 'login',
                  })
                }
              />
              <PrivateRoute path="/" component={App} />
            </Switch>
          </BrowserRouter>
        </ErrorBoundary>
      </QueryClientProvider>
    </RootStoreProvider>
  );
}
