import { Spin } from 'antd';
import { usePreview } from 'contexts/Preview.context';
import { useEffect, useRef, useState } from 'react';

const PreviewIframe = ({ gameUrl }: { gameUrl: string }) => {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { state } = usePreview();

  useEffect(() => {
    if (iframeRef.current?.contentWindow && state?.previewValuesToSend) {
      iframeRef.current.contentWindow.postMessage(
        state.previewValuesToSend,
        '*',
      );
    }
  }, [state.previewValuesToSend, loading]);

  return (
    <div className="preview__iframe-wrapper">
      {loading && <Spin size="large" className="preview__iframe-spinner" />}
      <iframe
        ref={iframeRef}
        frameBorder={0}
        className="preview__game-iframe"
        src={gameUrl}
        title="game"
        id="game_iframe"
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default PreviewIframe;
