import { AppLayout } from 'components/app-layout/layout';
import { AppRoute } from 'pages/app/app.route';

export default function AppIndex() {
  return (
    <AppLayout>
      <AppRoute />
    </AppLayout>
  );
}
