import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root/root.context';

export const UserNavBar = observer(function UserNavBar() {
  const { auth } = useStores();

  return (
    <Row justify="end">
      <Col>
        <Space>
          <Avatar size="small">
            <UserOutlined />
          </Avatar>
          {auth?.user?.name}
          <Button
            size="large"
            type="text"
            icon={<LockOutlined />}
            onClick={auth.logout}>
            Logout
          </Button>
        </Space>
      </Col>
    </Row>
  );
});
