const AsyncFormValidationError = (fields, e) => {
  const { errors } = e;
  return Object.keys(fields).map((key) => {
    const message = errors.find((item) => item.property === key);

    return {
      name: key,
      value: fields[key],
      errors: message?.errors ?? [],
    };
  });
};

export default AsyncFormValidationError;
