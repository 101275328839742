import loadable from '@loadable/component';
import { Loader } from 'components/loader'; /* webpackPrefetch: true */

/* _webpackChunkName: "single_camera" */

const AsyncPageLoadable = loadable(
  ({ page }: { page: string }) => import(`pages/${page}`),
  {
    ssr: false,
    fallback: <Loader />,
  },
);

const AsyncPage = (props) => <AsyncPageLoadable {...props} />;

export default AsyncPage;
