import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root/root.context';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type TProps = {
  component: React.FC;
  path: string;
} & RouteProps;

const PrivateRoute = (props: TProps) => {
  const { component: Component, ...rest } = props;
  const { auth } = useStores();
  const condition = auth?.isAuthenticated;

  return (
    <Route
      {...rest}
      render={(propsRoute) =>
        condition ? (
          <Component {...propsRoute} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default observer(PrivateRoute);
