import { Button, Col, Divider, Form, Input, InputNumber, Row } from 'antd';
import backgroundImage from 'assets/background.png';
import headerImage1 from 'assets/header1.png';
import popupImage from 'assets/popup.png';
import popupHeader1 from 'assets/popup-header.png';
import popupHeader2 from 'assets/popup-header-2.png';
import cardImage from 'assets/wheel.png';
import { NestedUploadFile } from 'components/upload-file/nested-upload-file';
import {
  IStyleItemsProps,
  UploadFile,
} from 'components/upload-file/upload-file';
import { PreviewActionType, usePreview } from 'contexts/Preview.context';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root/root.context';
import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import AsyncFormValidationError from 'services/errors/FormValidationsError';
import { L } from 'services/logger';
import debounce from 'utils/debounce';

const itemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};
const itemForm = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: 'Please input your ${label}',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

function StyleItems(props: IStyleItemsProps) {
  const { prefix = '', name = '' } = props;
  return (
    <>
      <Divider orientation="left">{name}</Divider>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Font"
            name={[prefix, 'style', 'fontFamily']}
            {...itemForm}>
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Font size"
            name={[prefix, 'style', 'fontSize']}
            {...itemForm}>
            <InputNumber min="0" max="100" step="0.1" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Color"
            name={[prefix, 'style', 'color']}
            {...itemForm}>
            <Input type="color" style={{ width: '90px' }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export const GameSettingsForm = observer(function SettingsForm({
  item,
}: {
  item?;
}) {
  const [form] = Form.useForm();
  const { games } = useStores();
  const { dispatch } = usePreview();
  const mutation = useMutation((data) =>
    item ? item.save(data) : games.add(data),
  );
  const history = useHistory();
  const onFinish = async (valuesData: any) => {
    try {
      await mutation.mutateAsync(valuesData);
      history.push('/games');

      // TODO: this code will refresh current page, but the data will not update, because it's fetched in GamesList component
      // history.push(window.location.pathname);
    } catch (e) {
      form.setFields(AsyncFormValidationError(form.getFieldsValue(), e));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    L.error('Failed:', errorInfo);
  };

  const initialValues = item
    ? {
        ...item,
        ...item.settings,
      }
    : {};

  const handleValuesChange = useCallback(
    debounce((_: any, allValues: object) => {
      dispatch({
        type: PreviewActionType.SET_PREVIEW_VALUE_TO_SEND,
        payload: allValues,
      });
    }, 500),
    [],
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: PreviewActionType.SET_PREVIEW_VALUE_TO_SEND,
        payload: form.getFieldsValue(),
      });
    }, 250);
  }, []);

  return (
    <Form
      {...itemLayout}
      autoComplete="off"
      form={form}
      name="user"
      validateMessages={validateMessages}
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      initialValues={initialValues}
      onFinishFailed={onFinishFailed}>
      <Divider orientation="left">General</Divider>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="ID Game" name="gameId" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Fonts" name="fonts">
        <Input.TextArea rows={4} />
      </Form.Item>
      <StyleItems name="Casino name" prefix="casinoName" />
      <StyleItems name="User name / Notification" prefix="userName" />

      <UploadFile
        name="Game background"
        prefix="background"
        form={form}
        value={item}
        example={backgroundImage}>
        <p>
          width: 1080px
          <br /> height: 1920px
        </p>
      </UploadFile>
      <NestedUploadFile
        name="Game card image"
        prefix="gameCard"
        childrenPrefixes={[
          'gameCard1',
          'gameCard2',
          'gameCard3',
          'gameCard4',
          'gameCard5',
          'gameCard6',
        ]}
        form={form}
        value={item}
        example={cardImage}>
        <p>
          width: 319px
          <br /> height: 325px
        </p>
      </NestedUploadFile>
      <UploadFile
        name="Header 1 image"
        prefix="header1"
        form={form}
        value={item}
        example={headerImage1}>
        <p>
          width: 1080px
          <br /> height: 468px
        </p>
      </UploadFile>
      <UploadFile
        name="Header 2 image"
        prefix="header2"
        form={form}
        value={item}>
        <p>
          width: 1080px
          <br /> height: 102px
        </p>
      </UploadFile>
      <StyleItems name="Prize Line 1" prefix="prizeLine1" />
      <StyleItems name="Prize Line 2" prefix="prizeLine2" />
      <UploadFile
        name="Pop-Up background"
        prefix="popup"
        form={form}
        value={item}
        example={popupImage}>
        <p>
          width: 1080px
          <br /> height: 1920px
        </p>
      </UploadFile>
      <UploadFile
        name="Congratulation Line 1 image"
        prefix="congratsLine1"
        form={form}
        value={item}
        example={popupHeader1}>
        <p>
          width: 1080px
          <br /> height: 292px
        </p>
      </UploadFile>
      <UploadFile
        name="Congratulation Line 2 image"
        prefix="congratsLine2"
        form={form}
        value={item}
        example={popupHeader2}>
        <p>
          width: 1080px
          <br /> height: 102px
        </p>
      </UploadFile>
      <StyleItems name="Disclaimer 1" prefix="disclaimer1" />
      <StyleItems name="Disclaimer 2" prefix="disclaimer2" />
      <StyleItems name="Disclaimer 3" prefix="disclaimer3" />
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
});
