import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, PageHeader, Space, Table } from 'antd';
import { ContentPage } from 'components/content';
import { values } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root/root.context';
import { useQuery } from 'react-query';
import { Link, useRouteMatch } from 'react-router-dom';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <Link to={`games/edit/${record.id}`}>{text}</Link>
    ),
  },
  {
    title: 'Game ID',
    dataIndex: 'gameId',
    key: 'gameId',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <Link to={`games/edit/${record.id}`}>Edit</Link>
        <Button type="link" onClick={record.remove}>
          Delete
        </Button>
      </Space>
    ),
  },
];

const GamesList = observer(function UsersList() {
  const { games } = useStores();
  const query = useQuery(['/games/list', games.page], () =>
    games.getList(games.page),
  );

  return (
    <Table
      columns={columns}
      dataSource={values(games.list)}
      loading={query.isLoading}
    />
  );
});

export function GamesListPage() {
  const { path } = useRouteMatch();

  return (
    <ContentPage
      header={
        <PageHeader
          title="Games"
          extra={[
            <Link to={`${path}/add`} key="add">
              <Button size="large" type="link" icon={<PlusCircleOutlined />}>
                Add
              </Button>
            </Link>,
          ]}
        />
      }>
      <GamesList />
    </ContentPage>
  );
}
