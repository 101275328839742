import {
  // HomeOutlined,
  PlayCircleOutlined,
  // UserOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';

import { MenuItem } from './menu-item';

const { Sider } = Layout;

export function MainMenu() {
  return (
    <Sider width={240} style={{ backgroundColor: 'white', padding: '24px' }}>
      {/* <MenuItem
        title="Dashboard"
        color="#B1ADF4"
        icon={<HomeOutlined />}
        location="/dashboard"
      /> */}
      <MenuItem
        location="/games"
        title="Games"
        color="#FF4D4F"
        icon={<PlayCircleOutlined />}
      />
      {/* <MenuItem
        location="/users"
        title="Users"
        color="#FA9E9E"
        icon={<UserOutlined />}
      /> */}
    </Sider>
  );
}
