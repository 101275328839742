import { Layout } from 'antd';
import { Header } from 'components/header';
import { MainMenu } from 'components/menu/main-menu';

const { Content } = Layout;

export function AppLayout({ children }) {
  return (
    <main className="main-layout">
      <Header />
      <section>
        <Layout style={{ height: '100%' }}>
          <MainMenu />
          <Content
            style={{
              margin: 0,
              minHeight: 480,
            }}>
            {children}
          </Content>
        </Layout>
      </section>
    </main>
  );
}
