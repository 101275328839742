import { message } from 'antd';

const clg = console;

function success(txt = '') {
  message.success(txt);

  if (process.env.NODE_ENV !== 'production') {
    clg.info(txt);
  }
}

function warn(txt = '') {
  message.warn(txt);

  if (process.env.NODE_ENV !== 'production') {
    clg.info(txt);
  }
}

function info(txt = '', duration = 5) {
  message.info(txt, duration);

  if (process.env.NODE_ENV !== 'production') {
    clg.info(txt);
  }
}

function error(txt, err, duration = 5) {
  const msg = txt || err.message;
  message.error(msg, duration);

  if (process.env.NODE_ENV !== 'production') {
    clg.error(err);
  }
}

function Logger() {
  return {
    ...console,
    error,
    success,
    warn,
    info,
  };
}

export const L = Logger();

export default L;
