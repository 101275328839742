import React, { createContext, useContext } from 'react';

export enum PreviewActionType {
  SET_PREVIEW_VALUE_TO_SEND = 'SET_PREVIEW_VALUE_TO_SEND',
  RESET = 'RESET',
}

type PreviewState = {
  previewValuesToSend: object | null;
};

const INITIAL_STATE: PreviewState = {
  previewValuesToSend: null,
};

type PreviewContextType = {
  state: PreviewState | null;
  dispatch: React.Dispatch<PreviewAction>;
};

const PreviewContext = createContext<null | PreviewContextType>(null);

export type PreviewAction =
  | { type: PreviewActionType.SET_PREVIEW_VALUE_TO_SEND; payload: object }
  | { type: PreviewActionType.RESET };

function previewReducer(
  state: PreviewState,
  action: PreviewAction,
): PreviewState {
  switch (action.type) {
    case PreviewActionType.SET_PREVIEW_VALUE_TO_SEND: {
      return {
        previewValuesToSend: action.payload,
      };
    }
    case PreviewActionType.RESET: {
      return INITIAL_STATE;
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

export const PreviewProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(previewReducer, INITIAL_STATE);
  const { children } = props;
  const value = { state, dispatch };

  return (
    <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>
  );
};

export const usePreview = () => {
  const store = useContext(PreviewContext);

  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
};
