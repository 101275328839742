import './style.css';

import { Layout } from 'antd';
import { CSSProperties } from 'react';

const { Content } = Layout;

type TContentPage = {
  children: React.ReactNode;
  header?: React.ReactNode;
  style?: CSSProperties;
};

export function ContentPage(props: TContentPage) {
  const { children, header, style } = props;
  return (
    <section style={style}>
      {header}
      <Layout className="content-layout">
        <Content className="content-page">{children}</Content>
      </Layout>
    </section>
  );
}
