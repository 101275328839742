import { PageHeader } from 'antd';
import { ContentPage } from 'components/content/content';
import { useLocation } from 'react-router-dom';

import { GameSettingsForm } from './game-settings-form';

export function GameAdd() {
  const location = useLocation();

  return (
    <ContentPage
      key={location.key}
      style={{ marginRight: 300 }}
      header={
        <PageHeader
          onBack={() => window.history.back()}
          title="Games"
          subTitle="Create settings"
        />
      }>
      <GameSettingsForm />
    </ContentPage>
  );
}
