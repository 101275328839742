import './style.css';

import { Avatar, Space } from 'antd';

export const Logo = () => (
  <h1 className="logo">
    <Space>
      <Avatar shape="square" size={40}>
        MG
      </Avatar>
      MiniGames
    </Space>
  </h1>
);
