import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Upload,
} from 'antd';
import { PreviewActionType, usePreview } from 'contexts/Preview.context';
import { TGameModel } from 'models/games/games.model';
import { Fragment, useState } from 'react';

const { Meta } = Card;

const itemForm = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

export interface IStyleItemsProps {
  prefix: string;
  name: string;
  children?: React.ReactNode;
}

type ChildPrefix = string;

type TempFiles = {
  [key: string]: string | null;
};

export function NestedUploadFile(
  props: IStyleItemsProps & {
    value?: TGameModel;
    example?: string;
    form: FormInstance;
    childrenPrefixes?: ChildPrefix[];
  },
) {
  const {
    prefix = '',
    childrenPrefixes = [],
    name = '',
    value,
    form,
    example,
    children,
  } = props;

  const getFile = (childPrefix: ChildPrefix) => {
    return value?.settings?.[prefix]?.[childPrefix]?.file
      ? `/upload/${value?.settings?.[prefix]?.[childPrefix]?.file}`
      : null;
  };
  const { dispatch } = usePreview();
  const [tempFiles, setTempFiles] = useState<TempFiles>(() => {
    const initialTempFiles = {};
    childrenPrefixes.forEach((childPrefix) => {
      if (childPrefix) {
        initialTempFiles[childPrefix] = getFile(childPrefix);
      }
    });
    return initialTempFiles;
  });

  const handleDeleteImage = (childPrefix: ChildPrefix) => {
    form.setFieldsValue({
      [prefix]: {
        [childPrefix]: { file: null },
      },
    });
    setTempFiles((previousTempFiles) => {
      const previousTempFilesCopy = { ...previousTempFiles };
      previousTempFilesCopy[childPrefix] = null;
      return previousTempFilesCopy;
    });
    dispatch({
      type: PreviewActionType.SET_PREVIEW_VALUE_TO_SEND,
      payload: form.getFieldsValue(),
    });
  };

  return (
    <>
      <Divider orientation="left" {...props}>
        {name}
      </Divider>
      {childrenPrefixes.map((childPrefix) => {
        return (
          <Form.Item
            key={childPrefix}
            hidden
            name={[prefix, childPrefix, 'file']}
            {...itemForm}>
            <Input />
          </Form.Item>
        );
      })}
      <Row gutter={[0, 32]}>
        {childrenPrefixes.map((childPrefix) => {
          return (
            <Fragment key={childPrefix}>
              <Col span={6} xxl={4}>
                <Upload
                  listType="picture-card"
                  multiple={false}
                  maxCount={1}
                  action={`${process.env.REACT_APP_API}/game-settings/upload`}
                  onChange={(info) => {
                    if (info.file.status === 'done') {
                      message.success(
                        `${info.file.name} file uploaded successfully`,
                      );
                      form.setFieldsValue({
                        [prefix]: {
                          [childPrefix]: {
                            file: info.file.response.filename,
                          },
                        },
                      });

                      setTempFiles((previousTempFiles) => {
                        const previousTempFilesCopy = { ...previousTempFiles };
                        previousTempFilesCopy[childPrefix] =
                          info.file.response.filename;
                        return previousTempFilesCopy;
                      });
                    } else if (info.file.status === 'error') {
                      message.error(`${info.file.name} file upload failed.`);
                    }

                    dispatch({
                      type: PreviewActionType.SET_PREVIEW_VALUE_TO_SEND,
                      payload: form.getFieldsValue(),
                    });
                  }}>
                  <div>
                    <PlusOutlined />
                  </div>
                </Upload>
              </Col>
              <Col span={6} xxl={4}>
                <Card
                  style={{ width: 150 }}
                  bordered={false}
                  size="small"
                  cover={
                    <img
                      alt=""
                      src={getFile(childPrefix)}
                      style={
                        !tempFiles[childPrefix]
                          ? { filter: 'grayscale(1)' }
                          : null
                      }
                    />
                  }>
                  <Meta title="Size" description={children} />
                  {!!example && (
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      href={example}
                      download={name}>
                      Download example
                    </Button>
                  )}
                  {tempFiles[childPrefix] && (
                    <Button
                      type="primary"
                      danger
                      onClick={() => handleDeleteImage(childPrefix)}>
                      Delete
                    </Button>
                  )}
                </Card>
              </Col>
            </Fragment>
          );
        })}
      </Row>
    </>
  );
}
