import { applySnapshot, flow, getEnv, types } from 'mobx-state-tree';

import { UserModel } from './user.model';

export const AuthModel = types
  .model('AuthModel', {
    token: types.optional(types.string, ''),
    isAuthenticated: types.optional(types.boolean, false),
    user: types.maybe(UserModel),
  })
  .actions((store) => {
    const { api } = getEnv(store);

    const getProfile = flow(function* getProfile() {
      const response = yield api.get('/auth/profile');

      return response.data;
    });

    return {
      login: flow(function* login(newToken: string) {
        api.defaults.headers.common.Authorization = `Bearer ${newToken}`;
        store.token = newToken;
        store.isAuthenticated = true;

        const user = yield getProfile();
        if (user) {
          store.user = UserModel.create(user);
        }
      }),
      afterCreate() {
        if (store.token.length > 0) {
          this.login(store.token);
        }
      },
      resetStore() {
        applySnapshot(store, {});
      },
      logout() {
        store.isAuthenticated = false;
        this.resetStore();
      },
    };
  });
